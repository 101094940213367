'use client'
import { type FC, useState, useEffect, useMemo} from 'react'
import { useTranslator, useDeviceType, useSession } from '@/hooks'
import styled from 'styled-components'
import { vw, mq, font60_65, font30_32, font15_15, getTransition, font18_18, font80_84, font40_44 } from '@/styles'
import dynamic from 'next/dynamic'
import { Button } from './Button'
import { motion, AnimatePresence } from 'framer-motion'
import { RandomWord } from './RandomWord'
import { SliderRandomWord } from './SliderRandomWord'
import { useRouter, useParams } from 'next/navigation'
const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

const videoSrc = {
  desktop: {
    src: 'https://vimeo.com/913671701',
  },
  mobile: {
    src: 'https://vimeo.com/913671733',
  }
}

const title_words = [
  { id: 0, text: 'Stylish photo of me in the 1940s, including a classic suit' },
  { id: 1, text: 'Cinematic photo of my daughter as an ethereal elf from Middle-earth' },
  { id: 2, text: 'Photo of my cat as a majestic Egyptian God' },
  { id: 3, text: 'Vibrant 1970s style photo of me, dressed in distinctive fashion of the 70s' },
  { id: 4, text: 'Photo of my son as a 19th-century french revolutionary from ‘Les Misérables’' },
  { id: 5, text: 'Cinematic photo of me dressed as a samurai warrior, holding a katana' },
  { id: 6, text: '3D image of my dog in the style of a Pixar movie' },
  { id: 7, text: 'Photo of me catching a wave while surfing, wearing a wetsuit' },
  { id: 8, text: 'Photo of my daughter swimming with dolphins, with a radiant smile' },
  { id: 9, text: 'Digital illustration of my cat in the ‘God of War’ game style' },
  { id: 10, text: 'Digital illustration of my son in the post-apocalyptic world of ‘The Last of Us’ TV series' },
  { id: 11, text: 'Art painting of me in the style of Katsuhiro Otomo' },
  { id: 12, text: 'Photo of my son at the helm of a sleek sailboat, sailing in the Mediter- ranean' },
  { id: 13, text: 'Photo of my dog snowboarding, and doing a flip in the french alps' },
  { id: 14, text: 'Painting of me in the style of Eugène Delacroix' },
  { id: 15, text: 'Photo of my cat dressed in iconic 90s rapper style' },
  { id: 16, text: 'Photo of my son as a vintage circus tightrope walker' },
  { id: 17, text: 'Photo of my dog dressed as Scheherazade, from Arabian Nights' },
  { id: 18, text: 'Rococo art painting of me in the style of Jean-Honoré Fragonard' },
]

const getRandomNumbers = () => {
  const numbers = []
  while(numbers.length < title_words.length){
    const random = Math.floor(Math.random() * title_words.length)
    if(numbers.indexOf(random) === -1) numbers.push(random)
  }
  return numbers
}

const slides = [
  { id: 0, name: 'classic' },
  { id: 1, name: 'cinematic' },
  { id: 2, name: 'egyptian' },
  { id: 3, name: 'fashion' },
  { id: 4, name: 'revolutionary' },
  { id: 5, name: 'samurai' },
  { id: 6, name: 'pixar' },
  { id: 7, name: 'surf' },
  { id: 8, name: 'dolphins' },
  { id: 9, name: 'game' },
  { id: 10, name: 'series' },
  { id: 11, name: 'otomo' },
  { id: 12, name: 'mediterranean' },
  { id: 13, name: 'alps' },
  { id: 14, name: 'delacroix' },
  { id: 15, name: 'rapper' },
  { id: 16, name: 'circus' },
  { id: 17, name: 'nights' },
  { id: 18, name: 'rococo' },
]

function onTabVisibilityChange(callback) {
  if (typeof callback !== 'function') {
    throw new Error('Callback should be a function')
  }

  // Function to check the visibility state
  const handleVisibilityChange = () => {
    const isTabActive = !document.hidden // `document.hidden` is false when tab is active
    callback(isTabActive)
  }

  // Listen for visibility changes
  document.addEventListener('visibilitychange', handleVisibilityChange)

  // Initial check to set the correct state
  handleVisibilityChange()
}

export const HomePage:FC = ({}) => {
  const [animationReady, setAnimationReady] = useState(false)
  const { t } = useTranslator()
  const { isMobile } = useDeviceType()
  const { push } = useRouter()
  const { user} = useSession()
  const { lang } = useParams()
  const randomNumbers = useMemo(() => getRandomNumbers(), [])
  const sortetTitleWords = title_words.sort((a, b) => randomNumbers.indexOf(a.id) - randomNumbers.indexOf(b.id)).map(item => item.text)
  const sortedSliders = slides.sort((a, b) => randomNumbers.indexOf(a.id) - randomNumbers.indexOf(b.id))
  const [isTabActive, setIsTabActive] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setAnimationReady(true)
    }, 1000)
  }, [])


  useEffect(() => {
    onTabVisibilityChange((isActive) => {
      setIsTabActive(isActive)
      if (isActive) {
        // Restart the animation when the tab becomes active
        setAnimationReady(false)
        setTimeout(() => {
          setAnimationReady(true)
        }, 100)
      }
    })
  }, [])

  return (
    <Main>
      <Section key={'decription'}>
        <p>{t('layout.home.title')}</p>
        <span>{t('layout.home.description')}</span>
      </Section>
      <Section className='word' key={'word'}>
        <AnimatePresence>
          {animationReady &&
          <>
            <motion.div key={'motion'} className='text-wrapper' variants={variants} {...basicVariantsProps} animate={animationReady && isTabActive ? 'animate' : 'initial'}>
              <RandomWord words={sortetTitleWords} />
            </motion.div>
            <SliderRandomWord animationReady={animationReady} slides={sortedSliders}/>
          </>
          }
        </AnimatePresence>
      </Section>
      <Button onClick={() => { push(user ? `/${lang ?? 'en'}/projects` : `/${lang ?? 'en'}/signup`) }} text={t('layout.home.second_block.action') as string}></Button>
      <Section key={'second'} className='second'>
        <p>{t('layout.home.second_block.title')}</p>
        <span>{t('layout.home.second_block.description')}</span>
        <span>{t('layout.home.second_block.subtitle')}</span>
      </Section>
      <Div  $isTouchDevice = {isMobile} className='video'>
        {isMobile && <ReactPlayer key='p-port' className='player-portrait' url={videoSrc.mobile.src} width={'100%'} height={'100%'} controls={false} volume={0} muted={true} loop={true} title={''} playsinline={true} playing={true} />}
        {!isMobile && <ReactPlayer key='p-land' className='player-landscape' url={videoSrc.desktop.src} width={'100%'} height={'100%'} controls={false} volume={0} muted={true} loop={true} title={''} playsinline={true} playing={true} />}
      </Div>
    </Main>

  )
}

const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px auto;
  max-width: 90%;
  width: 90%;

  ${mq.greaterThan('tablet')} {
    max-width: 900px;
  }

  ${mq.greaterThan('desktop-xs')} {
    max-width: 1100px;
  }

  &:last-of-type {
    max-width: 90%;
    width: 90%;

    ${mq.greaterThan('tablet')} {
      max-width: 500px;
    }

    ${mq.greaterThan('desktop-xs')} {
      max-width: 800px;
    }
    span:not(.cursor, .word, .second span) {
      &:first-of-type {
        ${font30_32(false, 600)}
      }
    }
  }

  p {
    ${font18_18(true, 600)}
    color: ${({ theme }) => theme.colors.neon_purple};
    display: block;
    letter-spacing: 1.5px;
    text-align: center;

    ${mq.greaterThan('tablet')} {
      ${font18_18(false, 600)}
      letter-spacing: 2px;
    }

  }
  span:not(.cursor, .word, .second span) {
    color: ${({ theme }) => theme.colors.white};
    display: block;
    text-align: center;

    &:first-of-type {
      ${font40_44(true, 600)}

      ${mq.greaterThan('tablet')} {
        ${font80_84(false, 600)}
      }
    }

    &:nth-of-type(2) {
      ${font15_15(true, 400)}
      color: ${({ theme }) => theme.colors.neon_purple};

      ${mq.greaterThan('nexus7')} {
        ${font18_18(true, 400)}
      }

      ${mq.greaterThan('desktop-xs')} {
        ${font15_15(false, 400)}
      }
    }
  }

  &.word {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: flex-start;
    margin: ${vw(50, 'mobile')} 0;
    min-height: 45vh;

    ${mq.greaterThan('nexus7')} {
      margin: ${vw(50, 'tablet')} 0
    }

    ${mq.greaterThan('tablet')} {
      justify-content: center;
      margin: ${vw(50, 'desktop')} 0 ;
    }

    ${mq.greaterThan('desktop-xs')} {
      max-width: 1300px;
    }

    ${mq.greaterThan('desktop')} {
      margin: 50px 0;
    }

    .text-wrapper {
      min-width: 60%;
      position: relative;
      width: 60%;

      ${mq.greaterThan('tablet')} {
        min-width: 50%;
        width: 50%;
      }
    }

    h1, span:not(.cursor, .word, .second span) {
      ${font30_32(true, 400)}
      color: ${({ theme }) => theme.colors.neon_purple};
      display: inline;
      grid-column: 1 / span 5;
      text-align: left;

      ${mq.greaterThan('tablet')} {
        ${font60_65(false, 400)}
        grid-column: 1 / span 6;
        grid-row: 1 / span 1;
      }
    }
  }

  &.second {
    > p {
      font-size: 12px;
      letter-spacing: 1.5px;
      text-align: center;
    }

    > span {
      color: ${({ theme }) => theme.colors.neon_purple};
      font-size: 18px;
      line-height: 20px;
      text-align: center;

      ${mq.greaterThan('tablet')} {
        font-size: 24px;
        line-height: 26px;
      }

      &:first-of-type{
        color: ${({ theme }) => theme.colors.text};
        font-size: 50px;
        font-weight: 600;
        line-height: 52px;
        text-align: center;
      }
    }
  }
`

const Main = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  max-width: 100%;
  overflow: hidden;

  ${mq.greaterThan('desktop')} {
    max-width: 1440px;
  }

  > button {
    border-radius: ${vw(100, 'mobile')};
    font-weight: 600;
    height: ${vw(80, 'mobile')};
    margin: ${vw(50, 'mobile')} auto ${vw(100, 'mobile')};
    padding: ${vw(15, 'mobile')} ${vw(50, 'mobile')} ${vw(17, 'mobile')} ${vw(50, 'mobile')};
    width: 95%;
    z-index: 0;

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(100, 'tablet')};
      height: ${vw(80, 'tablet')};
      margin: ${vw(60, 'tablet')} auto ${vw(100, 'tablet')};
      padding: ${vw(15, 'tablet')} ${vw(50, 'tablet')} ${vw(17, 'tablet')} ${vw(50, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${vw(100, 'desktop')};
      height: ${vw(80, 'desktop')};
      margin: ${vw(60, 'desktop')} auto ${vw(100, 'desktop')};
      padding: ${vw(15, 'desktop')} ${vw(50, 'desktop')} ${vw(17, 'desktop')} ${vw(50, 'desktop')};
      width: unset;
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 100px;
      height: 80px;
      margin: 60px auto 100px;
      padding: 15px 50px 17px 50px;
    }
  }

  section {
    gap: 10px;
    width: 95%;

    &:first-of-type {
      margin-top: ${vw(130, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-top: ${vw(130, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        margin-top: ${vw(170, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-top: 130px;
      }
    }

    &:nth-child(3){
      margin-bottom: ${vw(100, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-bottom: ${vw(100, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        margin-bottom: ${vw(100, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-bottom: 100px;
      }
    }

    &:nth-child(2) {
      margin-top: ${vw(110, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-top: ${vw(110, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        margin-top: ${vw(110, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-top: 110px;
      }
    }
  }

`

const Div = styled.div<{$isTouchDevice:boolean}>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${vw(825, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    height: ${vw(1400, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(723, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 800px;
  }

  > div {
    &[data-vimeo-initialized='true'] {
      height: 100%!important;
      width: 100%!important;
    }
  }

  iframe {
    aspect-ratio:393/770;
    height: auto !important;
    width: 100vw !important;

    ${mq.greaterThan('nexus7')} {
      aspect-ratio:420/770;
  }

    ${mq.greaterThan('tablet')} {
      aspect-ratio: 480/241;
      height: auto !important;
      left: 0;
      position: absolute;
      transform: unset;
      width: 100vw !important;
    }

    ${mq.greaterThan('desktop')} {
      aspect-ratio: 480/241;
      height: auto !important;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 1440px !important;
    }

  }
`

const variants = {
  initial : {
    y: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ...getTransition(),
      ease: [0.6,0.2,0.4,1.5],
    }
  }
}

const basicVariantsProps = {
  'initial': 'initial',
  'animate': 'animate',
  'exit': 'exit',
}
