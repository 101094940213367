'use client'
import { useState, useMemo } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { mq, vw } from '@/styles'
import { useInterval } from '@/hooks'

export const RandomWord = ({ words }):JSX.Element => {
  const [visible, setVisible] = useState(true)
  const [charIndex, setCharIndex] = useState(0)
  const [index, setIndex] = useState(0)
  const [word, setWord] = useState(words[index])
  const [wordDisplayed, setWordDisplayed] = useState('')

  const updateWord = () => {
    const incomingIndex = index < words.length - 1 ? index + 1 : 0
    if(index === words.length - 1 ){
      setTimeout(() => {
        setIndex(incomingIndex)
        setWord(words[incomingIndex])
      }, 3500)
    } else {
      setIndex(incomingIndex)
      setWord(words[incomingIndex])
    }
  }

  const type = () => {
    setWordDisplayed(wordDisplayed + word[charIndex])
    if(charIndex < word.length - 1) {
      setCharIndex(charIndex + 1)
    } else {
      setVisible(false)
      setCharIndex(0)
      updateWord()
    }
  }

  const hiddenDuration = 300
  const hiddenDelay =  3200
  // const intervalDelay = hiddenDuration + hiddenDelay

  const variants = {
    visible: {
      maxHeight: '500px',
      width: 'auto',
      transition: {
        duration: .2,
        type: 'tween',
        ease: [.25, .1, .25, 1],
      }
    },
    hidden: {
      maxHeight: 0,
      width: 0,
      transition: {
        duration: hiddenDuration/1000,
        type: 'tween',
        ease: [.25, .1, .25, 1],
        delay: hiddenDelay/1000
      }
    }
  }

  const testingWord = useMemo(() => word.length, [word])

  useInterval(() => {
    if(visible) {
      type()
    } else {
      setWordDisplayed('')
      setVisible(true)
    }
  }, visible ? 3500/testingWord : 3500)

  return (
    <Span>
      <Word className={'word'} variants={variants} initial={'visible'} animate={visible ? 'visible' : 'hidden'}>{wordDisplayed} <span className='cursor'></span></Word>
    </Span>
  )
}

const Span = styled.span`
  display: inline-block;
  min-height: ${vw(65, 'mobile')};
  position: relative;
  top: -20px;
  width: 100%;
  z-index: 200;

  ${mq.greaterThan('tablet')}{
    min-height: ${vw(90, 'desktop')};
    width: auto;
    z-index: unset;
  }

  .cursor {
    animation: fade .800s infinite 100ms;
    background: ${({ theme }) => theme.colors.neon_purple};
    display: inline-block;
    height: ${vw(28, 'mobile')};
    left: -5px;
    position: relative;
    top: 5px;
    width: 1px;

    ${mq.greaterThan('tablet')}{
      height: ${vw(50, 'desktop')};
      width: 2px;
    }

    ${mq.greaterThan('desktop')}{
      height: 50px;
    }

    @keyframes fade {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }
`

const Word = styled(motion.span)`
  display: inline-block;
  height: ${vw(300, 'mobile')};
  overflow: hidden;
  padding-bottom: ${vw(10, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    height: ${vw(500, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(500, 'desktop')};
    padding-bottom: ${vw(10, 'desktop')};
  }
  
  ${mq.greaterThan('desktop')} {
    height: 500px;
  }
`