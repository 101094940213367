'use client'
import { useMemo, useEffect, useState, useCallback } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { hex2Rgba, vw, mq } from '@/styles'
import { Figure } from './Figure'
import { iconByName } from '@/utils'
import { SliderFlickity } from './SliderFlickity'

const getImages = (item) => ({
  alt: `${item.id} | Aicam ${item.name}`,
  s: {
    size: { width: 552, height: 552 },
    src: `/images/aicam--${item.name}.jpg`
  }
})

const getSlides = (slides, loaderDisplayed ) => slides.map((item, idx) => {
  if (!item) {
    return (
      <motion.li key={`${idx}-loader`} className={`placeholder ${idx}`}>
        {loaderDisplayed && <Figure key={`${idx}-loader`} media={iconByName('loader')} className='loader' />}
      </motion.li>
    )
  }

  return (
    <motion.li key={`${idx}-${item.name}`} className={`image ${item.name}`}>
      <Figure media={getImages(item)} />
    </motion.li>
  )
})

let interval
// let second_interval
// let firstTime = true

export const SliderRandomWord = ({ animationReady, slides }):JSX.Element => {
  const [autoplayTime, setAutoplayTime] = useState(3500)
  const [loaderDisplayed, setLoadedDisplayed] = useState(false)
  const [cellIdx, setCellIdx] = useState(0)
  const slidesWithNulls = useMemo(() => slides.flatMap((item, _idx) => [null, item]), [slides])
  const Slides = useMemo(() => getSlides(slidesWithNulls, loaderDisplayed), [ loaderDisplayed, slidesWithNulls])
 
  useEffect(() => {
    if(animationReady) {
      //EVEN numbers are loaders and should be displayed for 3500ms
      //ODD numbers are images and should be displayed for 4500ms
      if(cellIdx % 2 === 0) {
        setAutoplayTime(3500)
        setLoadedDisplayed(true)
      } else {
        setLoadedDisplayed(false)
        setAutoplayTime(3500)
      }
    }
  },[animationReady, cellIdx])

  useEffect(() => {
    interval = setInterval(() => {
      setCellIdx(prevIndex => {
        const incomingIndex = prevIndex === slidesWithNulls.length -1 ? 0 : prevIndex + 1
        return incomingIndex
      })
    }, autoplayTime)

    return () => {
      clearInterval(interval)
      interval = null
    }
  },[cellIdx, autoplayTime, slidesWithNulls.length])

  // useEffect(() => {
  //   second_interval = setInterval(() => {
  //     if(animationReady && firstTime)
  //       firstTime = false
  //   }, 200)

  //   return () => {
  //     clearInterval(second_interval)
  //     second_interval = null
  //   }
  // }, [animationReady])
  
  const handleClick = useCallback((cellIndex) => {
    setCellIdx(cellIndex)
  }, [])

  return (
    <Div>
      <SliderFlickity className='slider--words' elementType='ul' options={{ pageDots:false, pauseAutoPlayOnHover: false}} onChange={handleClick} randomIndex={cellIdx}>
        {Slides}
      </SliderFlickity>
    </Div>
  )
}

const Div = styled(motion.div)`
  height: ${vw(264, 'mobile')};
  pointer-events: none;
  position: relative;
  right: 100px;
  width: ${vw(264, 'mobile')};
  z-index: 2;

  ${mq.greaterThan('nexus7')} {
    height: ${vw(264, 'tablet')};
    width: ${vw(264, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(544, 'desktop')};
    position: unset;
    right: unset;
    width: ${vw(544, 'desktop')};
    z-index: unset;
  }

  ${mq.greaterThan('desktop')} {
    height: 544px;
    width: 544px;
  }

  .flickity-viewport {
    display: flex;
    height: ${vw(264, 'mobile')};
    width: ${vw(264, 'mobile')};
    ${mq.greaterThan('nexus7')} {
      height: ${vw(264, 'tablet')};
      width: ${vw(264, 'tablet')};
    }
    ${mq.greaterThan('tablet')} {
      height: ${vw(544, 'desktop')};
      width: ${vw(544, 'desktop')};
    }
    ${mq.greaterThan('desktop')} {
      height: 544px;
      width: 544px;
    }
  }

  .flickity-slider, 
  .flickity-cell {
    display: grid;
    transform: translateX(0) !important;
  }

  .flickity-cell {
    grid-column: 1/ span 1;
    grid-row: 1/ span 1;
    opacity: 0;
    transform: translate(0, 0) !important;
    transition-timing-function: cubic-bezier(.25, .1, .25, 1);
    transition-duration: 800ms;
    transition-property: opacity transform;

    img {
      object-fit: cover;
      scale: 1.2;
      transition-timing-function: cubic-bezier(.25, .1, .25, 1);
      transition-duration: 800ms;
      transition-property: scale;
      width: 100%;
    }

    &.is-selected {
      opacity: 1;
      img {
        scale: 1;
      }
    }

    figure {
      height: ${vw(264, 'mobile')};
      overflow: hidden;
      width: 100%;
      z-index: 2;

      ${mq.greaterThan('nexus7')} {
        height: ${vw(264, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(544, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 544px;
      }

      &.loader {
        height: ${vw(100, 'mobile')};
        overflow: unset;
        width: ${vw(100, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          height: ${vw(100, 'tablet')};
          width: ${vw(100, 'tablet')};
        }

        ${mq.greaterThan('tablet')} {
          height: ${vw(100, 'desktop')};
          width: ${vw(100, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          height: 100px;
          width: 100px;
        }
      }
    }
  }

  .placeholder {
    align-items: center;
    background-color: ${({ theme }) => hex2Rgba(theme.colors.neon_purple, .2)};
    border-radius: ${vw(20, 'mobile')};
    display: flex;
    height: ${vw(264, 'mobile')};
    justify-content: center;
    width: ${vw(264, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(20, 'tablet')};
      height: ${vw(264, 'tablet')};
      width: ${vw(264, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${vw(20, 'desktop')};
      height: ${vw(544, 'desktop')};
      width: ${vw(544, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 20px;
      height: 544px;
      width: 544px;
    }
  }

 .image {
    figure {
      height: ${vw(264, 'mobile')};
      width: ${vw(264, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        height: ${vw(264, 'tablet')};
        width: ${vw(264, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(544, 'desktop')};
        width: ${vw(544, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 544px;
        width: 544px;
      }
    }
    
    figure, 
    img {
      border-radius: 20px;
    }
  }

  .loader {
    animation: loader .5s linear infinite;
    height: ${vw(100, 'mobile')};
    width: ${vw(100, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(100, 'tablet')};
      width: ${vw(100, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(100, 'desktop')};
      width: ${vw(100, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 100px;
      width: 100px;
    }

    @keyframes loader {
      0% {
        rotate: 0deg;
      }
      100% {
        rotate: 360deg;
      }
    }
  }
 `

